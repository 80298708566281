import axios from 'axios';
import { csrf } from './authServices';

const instance = axios.create();

instance.defaults.maxBodyLength = Infinity;
instance.defaults.maxContentLength = Infinity;

export async function getList() {
    try {
        const response = await instance.get('/api/admin/getList');
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function updateOne(detail, record) {
    try {
        await csrf();
        const sendData = {
            detail,
            record,
        };
        const response = await instance.post('/api/admin/updateOne', sendData);
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function deleteOne(detail, record) {
    try {
        await csrf();
        const sendData = {
            detail,
            record,
        };
        const response = await instance.delete('/api/admin/deleteOne', { data: sendData });
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function createBulkBots(websites, systemMsg) {
    try {
        await csrf();
        const sendData = {
            websites: websites,
            systemMsg,
        };
        const response = await instance.post('/api/admin/createBulkBots', sendData);
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function getBulkBots() {
    try {
        const response = await instance.get('/api/admin/getBulkBots');
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function getBulkBot(botId) {
    try {
        const response = await instance.get(`/api/chatbot/getBulkBot/${botId}`);
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function updateBulkBot(botId, checked) {
    try {
        await csrf();
        const sendData = {
            botId: botId,
            checked: checked,
        };
        await instance.post('/api/admin/updateBulkBot', sendData);
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function deleteBulkBot(botId) {
    try {
        await csrf();
        const sendData = {
            botId: botId,
        };
        const response = await instance.delete('/api/admin/deleteBulkBot', { data: sendData });
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function deleteBulkBots(ids) {
    try {
        await csrf();
        const sendData = {
            ids,
        };
        const response = await instance.delete('/api/admin/deleteBulkBots', { data: sendData });
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function getCalendlyURL(botId) {
    try {
        const response = await instance.get(`/api/chatbot/getCalendlyUrl/${botId}`);
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function getDefaultInterface() {
    try {
        const response = await instance.get('/api/chatbot/getDefaultInterface');
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function updateDefaultInterface(
    initialMsg,
    suggestMsg,
    theme,
    picture,
    removePicture,
    msgColor,
    icon,
    removeIcon,
    buttonColor,
    buttonAlign,
) {
    try {
        await csrf();
        const sendData = {
            initialMsg: initialMsg,
            suggestMsg: suggestMsg,
            theme: theme,
            picture: picture,
            removePicture: removePicture,
            msgColor: msgColor,
            icon: icon,
            removeIcon: removeIcon,
            buttonColor: buttonColor,
            buttonAlign: buttonAlign,
        };
        const response = await instance.post('/api/admin/updateDefaultInterface', sendData);
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function getDefaultSetting() {
    try {
        const response = await instance.get('/api/chatbot/getDefaultSetting');
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function updateBotSettings(
    baseprompt,
    temperature,
    visibility,
    domains,
    limit,
    limittime,
    limitmsg,
    addContext,
    context,
    rememberconv,
    numberconv,
    addlogs,
    addsource,
    summarize,
    model,
    maxTokens,
    topP,
    bestOf,
    frequency,
    presence,
    language,
    tone,
    profession,
) {
    try {
        await csrf();
        const sendData = {
            baseprompt,
            temperature,
            visibility,
            domains,
            limit,
            limittime,
            limitmsg,
            addContext,
            context,
            rememberconv,
            numberconv,
            addlogs,
            addsource,
            summarize,
            model,
            maxTokens,
            topP,
            bestOf,
            frequency,
            presence,
            language,
            tone,
            profession,
        };
        const response = await instance.post('/api/admin/updateBotSettings', sendData);
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function updateBasePrompt(prompts) {
    try {
        await csrf();
        const sendData = { prompts: prompts };
        const response = await instance.post('/api/admin/updateBasePrompt', sendData);
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function getBasePrompt() {
    try {
        const response = await instance.get('/api/chatbot/getBasePrompt');
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function addError(code, url, message, status, statusText, error) {
    try {
        const sendData = {
            code: code,
            url: url,
            message: message,
            status: status,
            statusText: statusText,
            error: error,
        };
        await instance.post('/api/admin/addError', sendData);
    } catch (err) {
        throw err;
    }
}

export async function deleteError(errorId) {
    try {
        await csrf();
        const sendData = {
            errorId: errorId,
        };
        const response = await instance.delete('/api/admin/deleteError', { data: sendData });
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function deleteErrors(ids) {
    try {
        await csrf();
        const sendData = {
            ids,
        };
        const response = await instance.delete('/api/admin/deleteErrors', { data: sendData });
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function getErrors() {
    try {
        const response = await instance.get('/api/admin/getErrors');
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function updateContext(contexts) {
    try {
        await csrf();
        const sendData = { contexts: contexts };
        const response = await instance.post('/api/admin/updateContext', sendData);
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function getContext() {
    try {
        const response = await instance.get('/api/chatbot/getContext');
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function getUsers() {
    try {
        const response = await instance.get('/api/admin/getUsers');
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function addAdmin(formData) {
    try {
        await csrf();
        const sendData = {
            name: formData.name,
            email: formData.email,
            password: formData.password,
            role: 'admin',
            level: 'Basic',
        };
        const response = await instance.post('/api/admin/addUser', sendData);
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function addUser(formData) {
    try {
        await csrf();
        const sendData = {
            name: formData.name,
            email: formData.email,
            password: formData.password,
            role: 'user',
            level: formData.level,
            validateUser: formData.validateUser,
        };
        const response = await instance.post('/api/admin/addUser', sendData);
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function updateAdmin(id, formData) {
    try {
        await csrf();
        const sendData = {
            id: id,
            name: formData.name,
            email: formData.email,
        };
        const response = await instance.post('/api/admin/updateAdmin', sendData);
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function updateUser(id, formData) {
    try {
        await csrf();
        const sendData = {
            id: id,
            name: formData.name,
            email: formData.email,
            level: formData.level,
            validateUser: formData.validateUser,
        };
        const response = await instance.post('/api/admin/updateUser', sendData);
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function deleteUser(id) {
    try {
        await csrf();
        const sendData = {
            id: id,
        };
        const response = await instance.delete('/api/admin/deleteUser', { data: sendData });
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function getSignUpSetting() {
    try {
        const response = await instance.get('/api/auth/getSignUpSetting');
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function updateSignUpSetting(status) {
    try {
        await csrf();
        const sendData = {
            status: status,
        };
        const response = await instance.post('/api/admin/updateSignUpSetting', sendData);
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function getPromptLogs() {
    try {
        const response = await instance.get('/api/admin/getPromptLogs');
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function createLevel(formData) {
    try {
        await csrf();
        const sendData = {
            name: formData.name,
            numberOfBots: formData.numberOfBots,
            numberOfQueries: formData.numberOfQueries,
            numberOfCharacters: formData.numberOfCharacters,
            enableFiles: formData.enableFiles,
            enableText: formData.enableText,
            enableWebsite: formData.enableWebsite,
            enableVideos: formData.enableVideos,
            gpt4: formData.gpt4,
            branding: formData.branding,
        };
        const response = await instance.post('/api/admin/createLevel', sendData);
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function getLevels() {
    try {
        const response = await instance.get('/api/admin/getLevels/');
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function getLevel(name) {
    try {
        const response = await instance.get(`/api/admin/getLevel/${name}`);
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function updateLevel(formData) {
    try {
        await csrf();
        const sendData = {
            name: formData.name,
            numberOfBots: formData.numberOfBots,
            numberOfQueries: formData.numberOfQueries,
            numberOfCharacters: formData.numberOfCharacters,
            enableFiles: formData.enableFiles,
            enableText: formData.enableText,
            enableWebsite: formData.enableWebsite,
            enableVideos: formData.enableVideos,
            gpt4: formData.gpt4,
            branding: formData.branding,
        };
        const response = await instance.post('/api/admin/updateLevel', sendData);
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function deleteLevel(name) {
    try {
        await csrf();
        const sendData = { name };
        await instance.delete('/api/admin/deleteLevel', { data: sendData });
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function getChatbots() {
    try {
        const response = await instance.get('/api/admin/getChatbots');
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function getResponses(botId) {
    try {
        const response = await instance.get(`/api/admin/getResponses/${botId}`);
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}
